<template>
  <LxpMobileHeader title="역량개발 학습목표 설정">
    <template v-slot:left>
      <div class="util util-back">
        <a href="javascript:" class="util-actions util-actions-back" @click="goBack">
          <i class="icon-history-back"></i>
        </a>
      </div>
    </template>
    <template v-slot:right>
      <div class="util util-download">
        <button class="util-actions util-actions-yellow" @click="clickSave">
          저장
        </button>
      </div>
    </template>
  </LxpMobileHeader>
  <!-- begin::kb-main -->
  <main class="kb-main" id="kb-talentboard-goal">
    <!-- main-content -->
    <div class="main-content min-component">
      <div class="goal-setting-container">
        <!-- content-section:목표설정 -->
        <section class="content-section">
          <header class="section-header">
            <h4 class="title">학습목표 설정</h4>
          </header>
          <div class="section-content">
            <div class="kb-form-fields">
              <!-- kb-form-item:현재역할 -->
              <div class="kb-form-item">
                <div class="kb-form-row">
                  <label class="kb-form-label">
                    <span class="kb-form-label-text">현재 학습분야</span>
                  </label>
                </div>
                <div class="kb-form-row">
                  <SortButtonSelect
                      v-model="params.now"
                      v-model:toggle="toggles.now"
                      title="현재 학습분야"
                      :options="works"
                      sequence-key="crseWorkDtlSn"
                      name-key="crseWorkDtlNm"
                      :is-num="true"
                      @update:toggle="closeToggles(toggles, 'now')"
                  />
<!--                  <div class="kb-form-dropdown" data-offset="bottom-left">-->
<!--                    <button class="kb-form-dropdown-btn"><span class="text">기업금융</span><i class="icon-arrow"></i></button>-->
<!--                  </div>-->
                </div>
              </div>
              <!-- kb-form-item:희망역할 -->
              <div class="kb-form-item">
                <div class="kb-form-row">
                  <label class="kb-form-label">
                    <span class="kb-form-label-text">희망 학습분야</span>
                  </label>
                </div>
                <div class="kb-form-row">
                  <SortButtonSelect
                      v-model="params.hope"
                      v-model:toggle="toggles.hope"
                      title="희망 학습분야"
                      :options="works"
                      sequence-key="crseWorkDtlSn"
                      name-key="crseWorkDtlNm"
                      :is-num="true"
                      @update:toggle="closeToggles(toggles, 'hope')"
                  />
                </div>
              </div>
              <!-- kb-form-item:목표기한 -->
              <div class="kb-form-item">
                <div class="kb-form-row">
                  <label class="kb-form-label">
                    <span class="kb-form-label-text">목표기한</span>
                  </label>
                </div>
                <div class="kb-form-row">
                  <TalentGoalCalendar
                      v-model="goalDate"
                      v-model:toggle="toggles.date"
                      id="dropdown-calendar-picker"
                  />
                </div>
              </div>
              <!-- kb-form-item:목표레벨 -->
              <div class="kb-form-item">
                <div class="kb-form-row">
                  <label class="kb-form-label">
                    <span class="kb-form-label-text">목표레벨</span>
                  </label>
                </div>
                <!-- running-level-fields -->
                <div class="running-level-fields">
                  <!-- running-level-container -->
                  <div class="running-level-container" :data-level="params.hope > 0 ? 4 : 0">
                    <div class="level-bar"><i class="icon-running"></i></div>
                    <ul class="level-steps">
                      <li class="level-step">
                        <div class="step-dot"></div>
                        <div class="step-content"><span class="title">초급</span>
                          <span v-if="talentLevel === 1" class="title-sub">현재레벨</span>
                        </div>
                      </li>
                      <li class="level-step">
                        <div class="step-dot"></div>
                        <div class="step-content"><span class="title">중급</span>
                          <span v-if="talentLevel === 2" class="title-sub">현재레벨</span>
                        </div>
                      </li>
                      <li class="level-step">
                        <div class="step-dot"></div>
                        <div class="step-content"><span class="title">고급</span>
                          <span v-if="talentLevel === 3" class="title-sub">현재레벨</span>
                        </div>
                      </li>
                      <li class="level-step">
                        <div class="step-dot"></div>
                        <div class="step-content">
                          <span class="title">전문가</span>
                          <span v-if="talentLevel === 4" class="title-sub text-yellow">이미 최대 레벨에 도달하셨습니다</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <!-- kb-form-item:목표레벨 -->
              <div class="kb-form-item">
                <div class="kb-form-row">
                  <label class="kb-form-label">
                    <span class="kb-form-label-text">목표</span>
                    <span class="kb-form-byte-text">{{ goalsCnCount }} / {{ maxLimitText }}</span>
                  </label>
                </div>
                <div class="kb-form-row">
                  <textarea v-model.trim="params.goalsCn" placeholder="목표를 입력하세요." class="kb-form-control"></textarea>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- content-section:취득희망 자격증 -->
        <section class="content-section">
          <header class="section-header">
            <h4 class="title">취득희망 자격증</h4>
            <div class="header-side"><span class="subtext">최대 5개 선택 가능</span></div>
          </header>

          <div class="kb-form-search mb-3" style="justify-content: end;">
            <div class="kb-form-search-field">
              <input v-model.trim="filters.keyword" type="text" class="kb-form-search-input" placeholder="자격증 검색하세요" @keyup.enter="getLicenseList(1)">
              <button class="kb-form-search-btn" @click="getLicenseList(1)"><i class="icon-search">검색</i></button>
            </div>
          </div>

          <div class="section-content">
            <div class="kb-form-check-group">
              <!-- kb-form-check -->
              <div v-for="(license, index) in goalLicenses" :key="index" class="kb-form-check kb-form-check-divider">
                <span v-if="isDisabledLicense(license.lcsnCd)">선택불가</span>
                <input v-else-if="!isLicenseGet(license.lcsnCd)" v-model="selected" type="checkbox" class="kb-form-check-input" :id="`chk_list_${license.lcsnCd}`" name="chk_list" :value="license.lcsnCd" @change="checkLicense($event, license.lcsnCd)"/>
                <span v-else></span>
                <label :for="`chk_list_${license.lcsnCd}`" class="kb-form-check-label">
                  <span class="label-text text-skip" style="width: 250px">{{ license.lcsnNm }} - {{ license.pblcnInstNm }} - {{ license.lcsnCd }}</span>
                  <span class="label-subtext">{{ license.grd1Cd }} ㅣ {{ isLicenseGet(license.lcsnCd) ? '취득' : '미취득' }}</span>
                </label>
              </div>
              <!-- kb-form-check -->
            </div>
            <CommonPaginationFront
                :paging="paging" :page-func="getLicenseList"
                :page-list-size="5"
            />
          </div>
        </section>
      </div>
    </div>
    <!-- //main-content -->
  </main>
</template>

<script>
import LxpMobileHeader from '@/views/layout/lxp/header/mobile/LxpMobileHeader';
import {computed, reactive, ref} from 'vue';
import {closeToggles, datePartToFormat, getListFunc} from '@/assets/js/ui.init';
import {
  ACT_GET_LRN_CRSE_WORK_ABILITY_GROUP_LIST,
  ACT_GET_LRN_CRSE_WORK_ACHV_LIST,
  ACT_GET_LRN_CRSE_WORK_GOAL_LIST,
  ACT_GET_LRN_CRSE_WORK_PHASE_LIST,
  ACT_INSERT_LRN_CRSE_WORK_GOALS,
  ACT_UPDATE_LRN_CRSE_WORK_GOALS,
} from '@/store/modules/talent/talent';
import {crseWorkTyCdDcds, getTargetLevel} from '@/assets/js/modules/mypage/talent-common';
import {ACT_GET_LCSN_GOAL_LIST, ACT_GET_LRN_SPRT_LCSN_LIST} from '@/store/modules/license/license';
import {useStore} from 'vuex';
import {useAlert} from '@/assets/js/modules/common/alert';
import {isSuccess} from '@/assets/js/util';
import SortButtonSelect from '@/components/common/SortButtonSelect';
import TalentGoalCalendar from '@/components/mypage/talent/TalentGoalCalendar';
import {useRouter} from 'vue-router';
import CommonPaginationFront from '@/components/CommonPaginationFront';
import {goBack} from '@/assets/js/modules/common/common';

export default {
  name: 'MobileMyTalentBoardGoal',
  components: {CommonPaginationFront, TalentGoalCalendar, SortButtonSelect, LxpMobileHeader},
  setup() {
    const store = useStore();
    const router = useRouter();
    const {showMessage, showConfirm} = useAlert();
    const session = computed(() => store.state.auth.session);
    const maxLimitText = 500;
    const disabledLicenses = ['WR96'];
    // Talent 항목들
    const works = ref([]);
    // 현재 내 설정
    const goals = ref([]);
    const abilities = ref([]);
    // Ace Academy 정보 (과정 + 자격증), 해당 항목은 난이도만 보면된다.
    const aces = ref([]);
    const licenses = ref([]);

    const licenseCodes = computed(() => licenses.value.map(x => x.lcsnCd));
    const crseWorkDtlSns = computed(() => works.value.map(x => x.crseWorkDtlSn));

    const readies = reactive({
      goal: false,
      ace: false,
      license: false
    });

    const allReadies = computed(() => {
      return readies.ability && readies.ace && readies.license && readies.goal;
    });

    const getNowGoal = () => {
      const now = goals.value.find(x => crseWorkDtlSns.value.includes(x.crseWorkDtlSn) && x.goalsDivCd === '00');
      return now ? now : {
        crseWorkDtlSn: 0
      };
    }

    const getHopeGoal = () => {
      const hope = goals.value.find(x => crseWorkDtlSns.value.includes(x.crseWorkDtlSn) && x.goalsDivCd === '01');
      return hope ? hope : {
        crseWorkDtlSn: 0
      };
    }

    const nowGoal = computed(getNowGoal);
    const hopeGoal = computed(getHopeGoal);

    const params = reactive({
      now: 0,
      hope: 0,
      goalsEndYmd: '',
      goalsCn: '',
      lcsnLst: '',
    });

    const toggles = reactive({
      works: false,
      now: false,
      hope: false,
      date: false,
    });
    const talentLevel = computed(() => getTargetLevel(works.value, abilities.value, aces.value, licenses.value, params.hope));


    const goalsCnCount = computed(() => {
      if(params.goalsCn){
        return params.goalsCn.length;
      }
      return 0;
    });

    const goalLicenses = ref([]);
    const selected = ref([]);
    const goalDate = ref(null);

    const paging = reactive({
      pageNo: 0,
      pageSize: 5,
      totalCount: 0
    });

    const filters = reactive({
      keyword: ''
    });

    const licenseLimit = 5;
    const isLicenseGet = (code) => {
      return licenseCodes.value.includes(code);
    }

    const isDisabledLicense = (code) => {
      return disabledLicenses.includes(code);
    }

    const checkLicense = (e, code) => {
      // length 체크
      if(e.target.checked){
        // 5보다 클경우
        if(selected.value.length > licenseLimit){
          if(selected.value.filter(x => !licenseCodes.value.includes(x)).length > licenseLimit){
            selected.value.splice(selected.value.indexOf(code), 1);
            showMessage('취득희망 자격증은 최대 5개만 선택할 수 있습니다.');
          }
        }
      }
    }

    const validateParams = () => {
      let result = false;
      let msg = '';

      if(params.now === 0){
        msg = '현재 역할을 선택해주세요.'
      }else if(params.hope === 0){
        msg = '희망 역할을 선택해주세요.'
      }else if(params.now === params.hope){
        msg = '현재 역할과 희망역할은 중복될 수 없습니다.'
      }else if(!goalDate.value){
        msg = '목표 기한을 설정해주세요.'
      }else if(!goalsCnCount.value){
        msg = '목표를 입력해주세요.'
      }else if(goalsCnCount.value > maxLimitText) {
        msg = `목표는 최대 ${maxLimitText}까지 작성 가능합니다.`
      }
      else{
        result = true;
      }

      if(!result) showMessage(msg);

      return result;
    };

    const isLoading = ref(false);
    const saveGoals = () => {
      // 현재 따로, 취득 따로.
      const nowParams = {
        goalsDivCd: '00',
        crseWorkDtlSn: params.now
      };
      const hopeParams = {
        goalsDivCd: '01',
        crseWorkDtlSn: params.hope,
        goalsEndYmd: datePartToFormat(goalDate.value, '-'),
        goalsCn: params.goalsCn,
      };

      const selectedLicense = selected.value.filter(x => !licenseCodes.value.includes(x));
      hopeParams.lcsnLst = selectedLicense.length > 0 ? selectedLicense.join(',') : '';

      const nowSave = nowGoal.value.crseWorkGoalsSn > 0 ?
          store.dispatch(`talent/${ACT_UPDATE_LRN_CRSE_WORK_GOALS}`, {crseWorkGoalsSn: nowGoal.value.crseWorkGoalsSn, params: nowParams}) :
          store.dispatch(`talent/${ACT_INSERT_LRN_CRSE_WORK_GOALS}`, nowParams);

      const hopeSave = hopeGoal.value.crseWorkGoalsSn > 0 ?
          store.dispatch(`talent/${ACT_UPDATE_LRN_CRSE_WORK_GOALS}`, {crseWorkGoalsSn: hopeGoal.value.crseWorkGoalsSn, params: hopeParams}) :
          store.dispatch(`talent/${ACT_INSERT_LRN_CRSE_WORK_GOALS}`, hopeParams);

      Promise.all([
        nowSave, hopeSave
      ])
      .then(([nRes, hRes]) => {
        isLoading.value = false;
        if(isSuccess(nRes) && isSuccess(hRes)){
          showMessage('역량개발 목표가 설정되었습니다.');
          router.push({name: 'MyTalentBoard'});
        }else{
          showMessage('오류가 발생했습니다. 다시 시도해주세요.');
        }
      })
      .catch(e => {
        console.error(e);
        showMessage('오류가 발생했습니다. 다시 시도해주세요.');
      });
    }

    const clickSave = () => {
      if(isLoading.value) return;

      if(validateParams()){
        isLoading.value = true;
        showConfirm({
          title: '목표 설정',
          text: '역량개발 목표를 설정하시겠습니까?',
          callback: saveGoals,
          closeCallback: () => {
            isLoading.value = false;
          },

        });
      }
    }


    // 사전 Get List
    const getTalentWorks = () => {
      getListFunc(`talent/${ACT_GET_LRN_CRSE_WORK_GOAL_LIST}`, null, goals, null, () => {
        const currentNowGoal = getNowGoal();
        const currentHopeGoal = getHopeGoal();

        params.now = currentNowGoal.crseWorkDtlSn;
        params.hope = currentHopeGoal.crseWorkDtlSn;
        params.goalsCn = currentHopeGoal.goalsCn;
        params.lcsnLst = currentHopeGoal.lcsnLst;

        if(currentHopeGoal.lcsnLst && currentHopeGoal.lcsnLst.length > 0){
          selected.value = currentHopeGoal.lcsnLst.split(',');
        }

        if(currentHopeGoal.crseWorkDtlSn > 0 && currentHopeGoal.goalsEndYmd){
          const split = currentHopeGoal.goalsEndYmd.split('-');
          if(split.length === 3){
            const dateSplit = split.map(x => parseInt(x));
            goalDate.value = {
              year: dateSplit[0],
              month: dateSplit[1],
              day: dateSplit[2]
            }
          }
        }
        readies.goal = true;

      });
    }

    const getLicenseList = (pageNo) => {
      paging.pageNo = pageNo ? pageNo : 1;
      getListFunc(`license/${ACT_GET_LCSN_GOAL_LIST}`, {sortCd: 'g', pageNo: paging.pageNo, pageSize: paging.pageSize, ...filters}, goalLicenses, paging, () => {});
    }

    getListFunc(`talent/${ACT_GET_LRN_CRSE_WORK_PHASE_LIST}`, {crseWorkTyCdDcd: crseWorkTyCdDcds.ability}, works, null, () => {getTalentWorks();});
    getListFunc(`talent/${ACT_GET_LRN_CRSE_WORK_ABILITY_GROUP_LIST}`, {crseWorkTyCdDcd: crseWorkTyCdDcds.ability, allYn: 'N'}, abilities, null, () => {readies.ability = true});
    getListFunc(`talent/${ACT_GET_LRN_CRSE_WORK_ACHV_LIST}`, {crseWorkTyCdDcd: crseWorkTyCdDcds.ace}, aces, null, () => {readies.ace = true});
    getListFunc(`license/${ACT_GET_LRN_SPRT_LCSN_LIST}`, {regYn: 'Y'}, licenses, null, () => {readies.license = true;});


    getLicenseList();

    return {
      maxLimitText,
      session,
      params,
      goalDate,
      goalLicenses,
      goalsCnCount,
      selected,
      paging,
      works,
      nowGoal,
      hopeGoal,
      licenseCodes,
      allReadies,
      toggles,
      talentLevel,
      filters,

      goBack,
      clickSave,
      getLicenseList,
      isLicenseGet,
      isDisabledLicense,
      checkLicense,
      closeToggles
    }


  }
};
</script>
